import { reactive, ref } from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
import { SEARCH_CONTACT_BY_PHONE } from '@/graphql/queries';

const state = reactive({
  timeout: null,
});

export function useContact() {
  const enabled = ref(false);
  const phone = ref('');
  const { result: contactResult } = useQuery(
    SEARCH_CONTACT_BY_PHONE,
    {
      phone,
    },
    {
      enabled: enabled,
    }
  );

  const contacts = useResult(
    contactResult,
    [],
    (data) => data.searchContactByPhone
  );

  const searchContact = (ph) => {
    console.log('🚀 ~ file: contact.js ~ line 29 ~ searchContact ~ ph', ph);
    if (ph && ph.length > 4) {
      enabled.value = true;
      phone.value = ph;
    }
  };

  const waitForTyping = (call) => {
    clearTimeout(state.timeout);
    state.timeout = setTimeout(() => {
      call();
    }, 500);
  };

  return { searchContact, waitForTyping, contacts };
}
