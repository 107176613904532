<template>
  <div class="time-tab">
    <div class="flex items-center flex-wrap md:flex-no-wrap mt-3">
      <span class="text-san-juan w-full md:w-auto leading-tight font-bold mb-3">
        Tipo de servicio
      </span>
      <div class="flex items-center mb-3 md:ml-3">
        <vs-checkbox v-model="inmediato"> </vs-checkbox>
        Inmediato
      </div>
      <p class="ml-3 flex items-center mb-3">
        <vs-checkbox v-model="programado"></vs-checkbox>
        Programado
      </p>
    </div>
    <vs-divider></vs-divider>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vx-input-group>
          <vs-input placeholder="Teléfono" v-model="telefono" />

          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-search"
              ></vs-button>
            </div>
          </template>
        </vx-input-group>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vx-input-group>
          <vs-input v-model="cliente" placeholder="Cliente" />

          <template slot="append">
            <div class="append-text btn-addon">
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-search"
              ></vs-button>
            </div>
          </template>
        </vx-input-group>
      </div>
    </div>
    <request-input-form letter="a" :request="request"></request-input-form>

    <div class="flex items-center flex-wrap md:flex-no-wrap mt-3">
      <span class="text-san-juan leading-tight font-bold mb-3">
        Forma de pago
      </span>
      <div class="flex items-center mb-3">
        <vs-checkbox v-model="contado"> </vs-checkbox>
        Contado
      </div>
      <p class="flex items-center mb-3">
        <vs-checkbox v-model="credito"></vs-checkbox>
        Crédito
      </p>
    </div>
    <vs-divider></vs-divider>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-input class="w-full" placeholder="$ Valor seguro"></vs-input>
      </div>

      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-input class="w-full" placeholder="$ Base"></vs-input>
      </div>

      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-select class="w-full" placeholder="Socio logístico"></vs-select>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-3">
        <vs-select
          class="w-full"
          placeholder="Nivel de socio logístico"
        ></vs-select>
      </div>
    </div>
    <vs-divider></vs-divider>
    <div class="flex flex-wrap">
      <vs-spacer class="hidden md:block"></vs-spacer>
      <vs-button class="mb-3" type="border"> Cancelar </vs-button>
      <vs-button class="bg-san-juan text-white ml-3 mb-3"> Calcular </vs-button>
      <vs-button class="ml-3 mb-3"> Enviar </vs-button>
    </div>
  </div>
</template>

<script>
import RequestInputList from './RequestInputList';
import RequestInputForm from './RequestInputForm';
export default {
  components: {
    RequestInputList,
    RequestInputForm,
  },
  data: () => ({
    inmediato: false,
    programado: false,
    contado: false,
    credito: false,
    idaVuelta: false,
    telefono: '',
    cliente: '',
    request: {
      direccion: '',
      descripcion: '',
    },
  }),

  methods: {
    addRequest() {
      if (this.requests.length <= 10) {
        this.requests.push({
          telefono: '',
          cliente: '',
          direccion: '',
          descripcion: '',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.time-tab {
}
</style>
