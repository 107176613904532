<template>
  <section>
    <div v-if="success">
      <p class="text-center">
        Archivo cargado correctamente. De click en importar para subir las
        ventas.
      </p>
    </div>
    <div v-if="!success" class="excel-import">
      <input
        type="file"
        ref="fileInput"
        class="hidden"
        accept=".xlsx, .xls"
        @change="handleClick"
      />
      <div
        @click="$refs.fileInput.click()"
        @drop="handleDrop"
        @dragover="handleDragover"
        @dragenter="handleDragover"
        class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl"
      >
        <feather-icon
          icon="UploadCloudIcon"
          svgClasses="h-16 w-16 stroke-current text-grey"
          class="block"
        />
        <span>Arrastre el archivo o </span>
        <span
          class="font-medium text-primary"
          @click.stop="$refs.fileInput.click()"
          >Busque</span
        >
        <!-- <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button> -->
      </div>
    </div>
  </section>
</template>

<script>
// import XLSX from 'xlsx';
import { read, utils } from 'xlsx';

export default {
  props: {
    onSuccess: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      excelData: {
        header: null,
        results: null,
        meta: null,
      },
      success: null,
    };
  },
  methods: {
    generateData({ header, results, meta }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
      this.onSuccess && this.onSuccess(this.excelData);
      this.success = true;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },

    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },

    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$vs.notify({
          title: 'Error de validación',
          text: 'Solo se subir un solo archivo.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$vs.notify({
          title: 'Error de validación',
          text: 'Solo se soportan los sufijos de archivos .xlsx, .xls, .csv',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });
        return false;
      }
      this.uploadFile(rawFile);
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = utils.sheet_to_json(worksheet);
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.uploadFile(rawFile);
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
    uploadFile(file) {
      this.$refs['fileInput'].value = null; // fix can't select the same excel
      this.readerData(file);
    },
  },
};
</script>
