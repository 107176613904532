<template>
  <vs-tabs alignment="fixed" class="send-service-tabs">
    <vs-tab label="Express">
      <express-tab></express-tab>
    </vs-tab>
    <!-- <vs-tab label="Time">
      <time-tab></time-tab>
    </vs-tab> -->
  </vs-tabs>
</template>

<script>
import ExpressTab from './ExpressTab.vue';
import TimeTab from './TimeTab.vue';
export default {
  components: {
    ExpressTab,
    TimeTab,
  },
};
</script>

<style></style>
