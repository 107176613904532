<template>
  <div class="express-tab">
    <ValidationObserver
      v-show="!creatingMultipleServicesLoading"
      v-slot="{ invalid }"
    >
      <section class="flex justify-between gap-1">
        <div class="flex items-center flex-wrap md:flex-no-wrap my-3">
          <span
            class="text-san-juan w-full md:w-auto leading-tight font-bold mb-3"
          >
            Tipo de servicio
          </span>
          <div class="flex items-center mb-3 md:ml-3">
            <vs-checkbox
              data-test="inmediatlyCheckbox"
              v-model="inmediato"
              @change="programado = !programado"
            >
            </vs-checkbox>
            Inmediato
          </div>
          <p class="ml-3 flex items-center mb-3">
            <vs-checkbox
              data-test="programmedCheckbox"
              v-model="programado"
              @change="inmediato = !inmediato"
            ></vs-checkbox>
            Programado
          </p>
        </div>
        <div>
          <vs-button
            @click.native="toggleImportSalesFromXlsx"
            data-test="uploadExcelButton"
            title="Importar ventas"
          >
            Importar ventas
          </vs-button>
        </div>
      </section>
      <div>
        <!-- Modal -->
        <vs-prompt
          :active.sync="showImportSalesFromXlsxPrompt"
          class="modal"
          title="Importar ventas"
          @accept="importSalesFromXlsx"
          accept-text="Importar"
          cancel-text="Descartar"
        >
          <div>
            <h1 class="font-semibold text-lg">
              Sube un archivo de excel con las siguientes columnas:
            </h1>
            <div class="mt-4 mb-4">
              <vs-select
                v-model="agoDeliveryInDays"
                class="w-full"
                placeholder="Día de las ventas"
              >
                <vs-select-item
                  v-for="(item, index) in ['Hoy', 'Ayer', '_']"
                  :key="index"
                  :value="index"
                  :text="item === '_' ? 'Hace ' + index + ' días' : item"
                />
              </vs-select>
            </div>
            <ImportExcel
              :onSuccess="importSalesFromXlsxFromBrowse"
            ></ImportExcel>
          </div>
        </vs-prompt>
      </div>
      <div v-if="programado" class="flex">
        <datepicker
          :disabledDates="disabledDates"
          :language="langEs"
          name="start-date"
          placeholder="Fecha"
          class="mr-2 w-1/2"
          v-model="fecha"
        ></datepicker>
        <!-- <flat-pickr
          :config="configdateTimePicker"
          v-model="time"
          :modelValue="time"
          class="w-1/2"
          label="Hora inicio"
          placeholder="00:00"
        /> -->
        <vs-input class="w-1/2" type="time" v-model="time" />
      </div>
      <vs-divider></vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-3">
          <ValidationProvider
            name="Teléfono"
            vid="Teléfono"
            v-slot="{ errors }"
            rules="required"
          >
            <vx-input-group>
              <vs-input
                data-test="phoneInput"
                placeholder="Teléfono"
                v-model="sender.phone"
                @keyup.native="typingPhone"
              />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="primary"
                    icon-pack="feather"
                    icon="icon-search"
                  ></vs-button>
                </div>
              </template>
            </vx-input-group>
            <div class="text-danger text-sm">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
        <div class="vx-col w-full md:w-1/2 mb-3">
          <ValidationProvider
            name="Cliente"
            vid="Cliente"
            v-slot="{ errors }"
            rules="required"
          >
            <vx-input-group>
              <vs-input
                v-model="sender.name"
                placeholder="Cliente"
                data-test="contactInput"
              />

              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button
                    color="primary"
                    icon-pack="feather"
                    icon="icon-search"
                  ></vs-button>
                </div>
              </template>
            </vx-input-group>
            <div class="text-danger text-sm">{{ errors[0] }}</div>
          </ValidationProvider>
        </div>
      </div>

      <request-input-list
        :requests="requests"
        @change="requestInputChange"
        @remove="removeRequest"
      ></request-input-list>

      <div
        class="flex items-center justify-between flex-wrap md:flex-no-wrap mt-3"
      >
        <span class="text-san-juan leading-tight font-bold mb-3">
          Forma de pago
        </span>
        <div class="flex items-center mb-3">
          <vs-checkbox
            data-test="cashCheckbox"
            v-model="contado"
            @change="credito = !credito"
          >
          </vs-checkbox>
          Contado
        </div>
        <p class="flex items-center mb-3">
          <vs-checkbox
            data-test="creditCheckbox"
            v-model="credito"
            @change="contado = !contado"
          ></vs-checkbox>
          Crédito
        </p>

        <span class="mb-3 flex items-center leading-tight">
          <vs-switch data-test="roundAndTripSwitch" v-model="idaVuelta" />
          <label class="ml-3">Ida y vuelta</label>
        </span>

        <div
          @click="addRequest"
          data-test="addRequestButton"
          class="mb-3 px-1 flex text-primary items-center cursor-pointer leading-tight"
        >
          <vs-icon
            size="small"
            icon="add_circle_outline"
            class="mr-3"
          ></vs-icon>
          Agregar dirección
        </div>
      </div>
      <div>
        <vs-divider></vs-divider>
        <div class="vx-row">
          <!-- <div class="vx-col w-full md:w-1/3 mb-3">
            <vs-input class="w-full" placeholder="$ Valor seguro"> </vs-input>
          </div>
          <div class="vx-col w-full md:w-1/3 mb-3">
            <vs-input class="w-full" placeholder="$ Parqueadero"></vs-input>
          </div> -->
          <div class="vx-col w-full md:w-1/4 mb-3">
            <vs-input
              v-model="value"
              class="w-full"
              data-test="valueInput"
              placeholder="$ Valor"
            ></vs-input>
          </div>
          <div v-if="inmediato" class="vx-col w-full md:w-1/4 mb-3">
            <vs-select
              v-model="vehicle_type"
              class="w-full"
              data-test="vehicleTypeSelect"
              placeholder="Tipo de Vehiculo"
              @change="requestInputChange"
            >
              <vs-select-item
                :data-test="'vehicleTypeSelectItem-' + index"
                v-for="(item, index) in vehicle_types"
                :key="index"
                :value="item.id"
                :text="item.alias"
              />
            </vs-select>
          </div>
          <div v-if="inmediato" class="vx-col w-full md:w-1/4 mb-3">
            <vs-select
              v-model="deliveryman"
              class="w-full"
              data-test="deliverymanSelect"
              placeholder="Socio logístico"
              autocomplete
            >
              <div
                class="items-select"
                v-for="(item, index) in deliverymen.filter(
                  (d) => d.data_status === 'verified'
                )"
                :key="index"
              >
                <vs-select-item
                  :value="item.id"
                  :data-test="'deliverymanSelectItem-' + index"
                  :text="`${item.code ? item.code : 'N/A'} - ${
                    item.user.first_name +
                    ' ' +
                    item.user.last_name +
                    ' ' +
                    item.data_status
                  }`"
                />
              </div>
            </vs-select>
          </div>
          <div v-if="inmediato" class="vx-col w-full md:w-1/4 mb-3">
            <vs-select
              v-model="level"
              class="w-full"
              data-test="experienceSelect"
              placeholder="Nivel de socio logístico"
            >
              <vs-select-item :value="null" text="Sin filtro de experiencia" />
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="`${item.name}`"
                v-for="(item, index) in levels"
              />
            </vs-select>
          </div>
        </div>
      </div>
      <vs-divider></vs-divider>

      <div class="flex flex-wrap">
        <vs-spacer class="hidden md:block"></vs-spacer>
        <vs-button
          data-test="cancelButton"
          class="mb-3"
          type="border"
          @click="resetForm"
        >
          Cancelar
        </vs-button>
        <vs-button
          data-test="calculateButton"
          class="bg-san-juan text-white ml-3 mb-3"
          @click="calculateCost"
        >
          Calcular
        </vs-button>
        <vs-button
          data-test="sendButton"
          class="ml-3 mb-3"
          :disabled="!valid || invalid"
          @click.native="confirmRequest"
        >
          Enviar
        </vs-button>
      </div>
    </ValidationObserver>
    <section v-show="creatingMultipleServicesLoading">
      <div class="my-10 flex justify-center">
        <app-loading :loading="creatingMultipleServicesLoading"></app-loading>
      </div>
    </section>
  </div>
</template>

<script>
import RequestInputList from './RequestInputList';
import Datepicker from 'vuejs-datepicker';
import { es } from 'vuejs-datepicker/src/locale';
import moment from 'moment';
import { DELIVERYMEN, EXP_LEVELS, GET_VEHICLE_TYPES } from '@/graphql/queries';
import { useContact } from '@/modules/contact';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
  onMounted,
} from '@vue/composition-api';
import { useQuery, useResult } from '@vue/apollo-composable';
import apolloClient from '@/plugins/vue-apollo';
import ImportExcel from '@/components/excel/ImportExcel.vue';
import { MULTIPLE_REQUEST_SERVICE } from '@/graphql/mutations';
import AppLoading from '@/components/AppLoading';

const initState = () => {
  return {
    inmediato: true,
    programado: false,
    contado: true,
    langEs: es,
    credito: false,
    idaVuelta: false,
    telefono: '',
    fecha: new Date(),
    time: moment(new Date()).format('hh:mm'),
    cliente: '',
    value: null,
    disabledDates: {
      to: new Date(Date.now() - 8640000),
    },
    configdateTimePicker: {
      enableTime: true,
      defaultDate: new Date(),
      minTime: new Date(),
      enableSeconds: true,
      noCalendar: true,
    },
    // TODO: No usar el ID, porque puede cambiar en el futuro
    vehicle_type: 3, // El id 3 en la db es Moto
    vehicle_types: [],
    requests: [
      {
        address: '',
        description: '',
        coordinates: [],
      },
      {
        address: '',
        description: '',
        coordinates: [],
      },
    ],
    deliveryman: [],
    level: null,
    showImportSalesFromXlsxPrompt: false,
    creatingMultipleServicesLoading: false,

    agoDeliveryInDays: 0,
  };
};
export default defineComponent({
  components: {
    RequestInputList,
    Datepicker,
    ImportExcel,
    AppLoading,
  },
  setup(_, { root }) {
    onMounted(() => {
      getVehicleTypes();
    });

    const state = reactive(initState());
    const costDetails = computed(() => root.$store.state.request.costDetails);

    watch(
      () => state.fecha,
      (value) => {
        state.time = new Date();
        if (
          moment(value).format('YYYY-MM-DD') !=
          moment(new Date()).format('YYYY-MM-DD')
        ) {
          state.configdateTimePicker.minTime = '';
        } else {
          state.configdateTimePicker.minTime = new Date();
        }
      }
    );
    /**ExpLevels query */
    const { result: expLevelsResult } = useQuery(EXP_LEVELS);
    const levels = useResult(expLevelsResult, [], (data) => data.exp_levels);
    /** Deliverymen query */
    const { result: deliverymenResult, refetch: refetchDeliverymen } =
      useQuery(DELIVERYMEN);

    /** Watch ally level  */
    watch(
      () => state.level,
      (value) => {
        state.deliveryman = '';
        refetchDeliverymen({
          exp_level_id: value,
        });
      }
    );

    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.deliverymen
    );
    const requestWithCoordinates = computed(() =>
      state.requests.filter(
        (request) => request.coordinates.lat && request.coordinates.lng
      )
    );
    const coordinates = computed(() =>
      requestWithCoordinates.value.map((request) => {
        return { lat: request.coordinates.lat, lng: request.coordinates.lng };
      })
    );

    const {
      searchContact,
      waitForTyping: waitForTypingContact,
      contacts,
    } = useContact();

    const sender = ref({
      phone: '',
      name: '',
      street: '',
      coordinates: {},
    });

    const calculateCost = async () => {
      if (coordinates.value.length >= 2) {
        // console.log(state.requests[0].city.id);
        await root.$store
          .dispatch('request/calculateServiceCost', {
            coordinates: coordinates.value,
            roundTrip: state.idaVuelta,
            vehicleType: state.vehicle_type,
            city_id: state.requests[0].city.id,
          })
          .catch((error) => {
            root.$vs.notify({
              color: 'danger',
              title: 'Error en el cálculo de costo',
              text: `${error.graphQLErrors.map((r) => r.message)}`,
              position: 'top-right',
            });
          });
      }
    };

    const getVehicleTypes = async () => {
      const response = await apolloClient.query({
        // Same options like above
        query: GET_VEHICLE_TYPES,
      });

      state.vehicle_types = response.data.vehicleTypes;
    };

    const requestInputChange = () => {
      calculateCost();
    };

    const typingPhone = () => {
      waitForTypingContact(() => {
        sender.value.phone && searchContact(sender.value.phone);
        if (contacts.length) {
          sender.value.name = contacts[0].name;
          // sender.value.coordinates = ct[0].coordinates;
          // sender.value.street = ct[0].street;

          state.requests[0].coordinates = {
            lat: contacts[0].coordinates.lat,
            lng: contacts[0].coordinates.lng,
          };
          state.requests[0].address = contacts[0].street;
          if (contacts[0].coordinates.lat && contacts[0].coordinates.lng) {
            calculateCost();
          }
        }
      });
    };

    const valid = computed(() => {
      return requestWithCoordinates.value.length >= 2;
    });

    const requestService = () => {
      const payment_method = state?.contado ? 'CASH' : 'CREDIT';

      const dateTime =
        moment(state.fecha).format('YYYY-MM-DD') + ' ' + state.time;

      const payload = {
        round_trip: state.idaVuelta,
        directions: state.requests,
        value: costDetails.value.consolidado,
        description: state.requests[0].description,
        payment_method,
        client_type: 'NORMAL',
        calculation_cost: {
          coordinates: coordinates.value,
          round_trip: state.idaVuelta,
          vehicle_type_id: state.vehicle_type,
          city_id: state.requests[0].city.id,
        },
      };
      if (sender.value.phone && sender.value.name) {
        sender.value.coordinates = state.requests[0].coordinates;
        sender.value.street = state.requests[0].address;
        payload.sender = sender.value;
      }

      // Si el usuario especifica un valor, el consolidado no se tomará en cuenta
      if (state.value && state.value !== '') {
        payload.value = state.value;
      }

      if (state.deliveryman) {
        payload.deliveryman_id = Number(state.deliveryman);
      }

      if (state.programado) {
        payload.programmed_at = moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
      }
      console.log(payload);
      state.creatingMultipleServicesLoading = true;
      root.$store
        .dispatch('request/requestService', payload)
        .then(() => {
          resetForm();
          state.creatingMultipleServicesLoading = false;
          root.$vs.notify({
            color: 'success',
            title: 'Servicio enviado sactifactoriamente.',
            text: 'Hemos recibido tu solicitud!',
            position: 'top-right',
          });
          root.$router.push({ name: 'panel-de-control' });
        })
        .catch((error) => {
          state.creatingMultipleServicesLoading = false;
          root.$vs.notify({
            color: 'danger',
            title: 'Servicio no enviado.',
            text: `${error.graphQLErrors.map((r) => r.message)}`,
            position: 'top-right',
          });
        });
    };

    const confirmRequest = () => {
      root.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: `Solicitar servicio`,
        text: `¿Estás seguro de solicitar el servicio?`,
        accept: requestService,
        acceptText: 'Aceptar',
      });
    };

    const addRequest = () => {
      if (state.requests.length <= 10) {
        state.requests.push({
          address: '',
          description: '',
          coordinates: [],
        });
      }
    };

    const removeRequest = (index) => {
      state.requests.splice(index, 1);
      // TODO - Recalculate cost
    };

    const resetForm = () => {
      sender.value.phone = sender.value.name = '';
      Object.assign(state, initState());
    };

    /** Watch contacts finded */
    watch(contacts, (ct) => {
      if (ct.length) {
        sender.value.name = ct[0].name;
        // sender.value.coordinates = ct[0].coordinates;
        // sender.value.street = ct[0].street;

        state.requests[0].coordinates = {
          lat: ct[0].coordinates.lat,
          lng: ct[0].coordinates.lng,
        };
        state.requests[0].address = ct[0].street;
        if (ct[0].coordinates.lat && ct[0].coordinates.lng) {
          calculateCost();
        }
      }
    });

    /**
     * Round and trip watch
     */
    watch(
      () => state.idaVuelta,
      () => {
        calculateCost();
      }
    );

    const toggleImportSalesFromXlsx = () => {
      state.showImportSalesFromXlsxPrompt =
        !state.showImportSalesFromXlsxPrompt;
    };

    const importSalesFromXlsx = () => {
      console.log(
        '✅ importSalesFromXlsx',
        state.showImportSalesFromXlsxPrompt
      );
      if (!state.multipleServices) {
        root.$vs.notify({
          color: 'danger',
          title: 'No se ha cargado ningún archivo.',
          text: `Debe cargar el archivo primero.`,
          position: 'top-right',
        });
        return;
      }

      if (state.agoDeliveryInDays === null) {
        root.$vs.notify({
          color: 'danger',
          title: 'Día de las ventas.',
          text: `Debe seleccionar un día se subida de ventas.`,
          position: 'top-right',
        });
        return;
      }

      state.creatingMultipleServicesLoading = true;
      root.$apollo
        .mutate({
          mutation: MULTIPLE_REQUEST_SERVICE,
          variables: {
            input: {
              services: state.multipleServices,
              agoDeliveryInDays: state.agoDeliveryInDays,
            },
          },
        })
        ?.then(() => {
          state.creatingMultipleServicesLoading = false;
          root.$vs.notify({
            color: 'success',
            title: 'Servicios enviados sactifactoriamente.',
            text: '¡Hemos recibido tus solicitudes!',
            position: 'top-right',
          });
        })
        ?.catch(() => {
          state.creatingMultipleServicesLoading = false;
        });
    };

    const importSalesFromXlsxFromBrowse = (data) => {
      try {
        const multipleServices = data.results
          .filter((r) => {
            return (
              r['Descripcion punto A'] &&
              r['Descripcion punto B'] &&
              r['Telefono'] &&
              r['COD']
            );
          })
          .map((r) => {
            const _payMethods = (type) => {
              if (
                ['credito', 'crédito'].includes(
                  type?.toLowerCase()?.replace(' ', '')
                )
              ) {
                return 'CREDIT';
              }
              return 'CASH';
            };
            return {
              round_trip: false,
              directions: [
                {
                  address: 'Cll 85 # 25 - 116',
                  description: r['Descripcion punto A'],
                  coordinates: {
                    lat: 7.0917499936612955,
                    lng: -73.10974999941408,
                  },
                  city: {
                    id: '118',
                    name: 'Bucaramanga',
                  },
                },
                {
                  address: 'Cll 85 # 25 - 116',
                  description: r['Descripcion punto B'],
                  coordinates: {
                    lat: 7.0917499936612955,
                    lng: -73.10974999941408,
                  },
                  city: {
                    id: '118',
                    name: 'Bucaramanga',
                  },
                },
              ],
              value: r[' TOTAL'],
              description: r['Descripcion punto A'],
              payment_method: _payMethods(r['Método de pago']),
              client_type: 'NORMAL',
              calculation_cost: {
                coordinates: [
                  {
                    lat: 7.0917499936612955,
                    lng: -73.10974999941408,
                  },
                  {
                    lat: 7.0917499936612955,
                    lng: -73.10974999941408,
                  },
                ],
                round_trip: false,
                vehicle_type_id: 3,
                city_id: '118',
              },
              sender: {
                phone: `${r['Telefono']}`,
                name: r['Descripcion punto A'],
                street: 'Cll 85 # 25 - 116',
                coordinates: {
                  lat: 7.0917499936612955,
                  lng: -73.10974999941408,
                },
              },
              deliveryman_code: r['COD'],
            };
          });
        state.multipleServices = multipleServices;
      } catch (error) {
        console.error(error);
        root.$vs.notify({
          color: 'danger',
          title: 'No se ha podido leer correctamente el archivo.',
          text: 'Revisar las entradas del archivo y las columnas cargadas.',
          position: 'top-right',
        });
      }
    };

    return {
      ...toRefs(state),
      costDetails,
      deliverymen,
      requestWithCoordinates,
      coordinates,
      confirmRequest,
      requestInputChange,
      addRequest,
      resetForm,
      calculateCost,
      removeRequest,
      typingPhone,
      toggleImportSalesFromXlsx,
      importSalesFromXlsx,
      importSalesFromXlsxFromBrowse,
      valid,
      sender,
      levels,
    };
  },
});
</script>

<style lang="scss"></style>
