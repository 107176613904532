<template>
  <div class="solicitud">
    <!-- Prompt historial llamadas -->
    <vs-prompt
      class="custom-prompt solicitud__llamadas-prompt"
      title="Historial de llamadas"
      accept-text="Exportar"
      cancel-text="Descartar"
      :active.sync="llamadasPrompt"
    >
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-3">
          <label class="text-sm text-san-juan font-bold">Fecha de inicio</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesFrom"
            name="start-date"
            v-model="startDate"
          ></datepicker>
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-3">
          <label class="text-sm text-san-juan font-bold">Fecha de fin</label>
          <datepicker
            :language="langEs"
            :disabledDates="disabledDatesTo"
            name="end-date"
            v-model="endDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            placeholder="Buscar..."
            v-model="search"
          ></vs-input>
        </div>
      </div>
      <vs-table
        class="mt-3 leading-tight"
        max-items="3"
        pagination
        search
        ref="tablaLlamadas"
        stripe
        :data="historialLlamadas"
      >
        <template slot="thead">
          <vs-th> Teléfono </vs-th>
          <vs-th> Nombre </vs-th>
          <vs-th> Dirección </vs-th>
          <vs-th> Hora llamada </vs-th>
          <vs-th> Hora tomada </vs-th>
          <vs-th> Check </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].telefono">
              {{ data[indextr].telefono }}
            </vs-td>
            <vs-td :data="data[indextr].nombre">
              {{ data[indextr].nombre }}
            </vs-td>
            <vs-td :data="data[indextr].direccion">
              {{ data[indextr].direccion }}
            </vs-td>
            <vs-td :data="data[indextr].horaLlamada">
              {{ data[indextr].horaLlamada }}
            </vs-td>
            <vs-td :data="data[indextr].horaTomada">
              {{ data[indextr].horaTomada }}
            </vs-td>

            <vs-td :data="data[indextr].check">
              <vs-checkbox :value="data[indextr].check"></vs-checkbox>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
    <!-- ./Prompt historial llamadas -->
    <div class="vx-row">
      <!-- <div class="vx-col w-full lg:w-1/3 xl:w-1/4">
        <vx-card class="mb-6" title="Llamadas entrantes">
          <vs-button
            @click="llamadasPrompt = true"
            class="font-bold w-full mb-3"
            type="border"
          >
            Ver historial
          </vs-button>

          <div v-for="n in 7" :key="n" class="flex justify-between flex-wrap">
            <span>
              <p class="text-xl font-bold">621175626</p>
              <p class="text-grey">Calle 41 #19-51 Piccadilly Oficina 1</p>
            </span>
            <span>
              <p class="text-grey mt-4 md:mt-0">6:00pm</p>
            </span>
            <vs-divider class="mt-3"></vs-divider>
          </div>
        </vx-card>
      </div> -->
      <div class="vx-col w-full lg:w-8/12">
        <vx-card class="mb-6">
          <SendServiceTabs></SendServiceTabs>
        </vx-card>
      </div>
      <div class="vx-col w-full lg:w-4/12">
        <vx-card title="Resumen">
          <div class="flex justify-between flex-wrap mt-6">
            <span>
              <p class="font-bold text-slate-gray">Km:</p>
            </span>
            <span>
              <p class="text-grey text-san-juan font-bold">
                {{ costDetails.distancia.text }}
              </p>
            </span>
          </div>
          <div class="flex justify-between flex-wrap mt-3">
            <span>
              <p class="font-bold text-slate-gray">Minutos:</p>
            </span>
            <span>
              <p class="text-grey text-san-juan font-bold">
                {{ costDetails.tiempo.text }}
              </p>
            </span>
          </div>
          <!-- Seguro -->
          <div class="flex justify-between flex-wrap mt-3">
            <span>
              <p class="font-bold text-slate-gray">Seguro:</p>
            </span>
            <span>
              <p class="text-grey text-san-juan font-bold">
                {{ 0 | currencyFormat }}
              </p>
            </span>
          </div>
          <!-- Adicional -->
          <div class="flex justify-between flex-wrap mt-3">
            <span>
              <p class="font-bold text-slate-gray">Adicional:</p>
            </span>
            <span>
              <p class="text-grey text-san-juan font-bold">
                {{ 0 | currencyFormat }}
              </p>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between flex-wrap mt-3">
            <span>
              <p class="text-lg font-bold text-san-juan">Total:</p>
            </span>
            <span>
              <p class="text-grey text-san-juan font-bold">
                {{ costDetails.consolidado | currencyFormat }}
              </p>
            </span>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import SendServiceTabs from '@/components/solicitud/SendServiceTabs';
import { es } from 'vuejs-datepicker/src/locale';
import historialLlamadas from './historialLlamadas.json';
import { CURRENT_SERVICES, PROGRAMMED_SERVICES } from '@/graphql/queries';
import Datepicker from 'vuejs-datepicker';

export default {
  components: {
    SendServiceTabs,
    Datepicker,
  },
  async mounted() {
    await this.$apollo.query({
      query: CURRENT_SERVICES,
      variables: {
        page: 1,
        first: 5,
      },
    });
    await this.$apollo.query({
      query: PROGRAMMED_SERVICES,
    });
  },
  data: () => ({
    // Prompt llamadas
    llamadasPrompt: false,
    langEs: es,
    startDate: '',
    endDate: '',
    search: '',
    checkLlamada: false,
    historialLlamadas: historialLlamadas,
  }),
  computed: {
    disabledDatesTo() {
      return { to: new Date(this.startDate) };
    },
    disabledDatesFrom() {
      return { from: new Date(this.endDate) };
    },

    costDetails() {
      return this.$store.state.request.costDetails;
    },
  },
  watch: {
    search(value) {
      this.$refs.tablaLlamadas.searchx = value;
    },
  },
};
</script>

<style lang="scss">
.solicitud {
  &__llamadas-prompt {
    .vs-dialog {
      @screen xl {
        max-width: 50%;
      }
    }
    .vs-table--header {
      display: none !important;
    }
  }
}
</style>
